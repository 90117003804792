/* eslint-disable max-len */
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
} from 'ants/resource';
import { objectAsArray, arrayAsObject } from 'lib/utils';
import moment from 'moment';
import { getSport, getAccountKey } from 'core/localStore';
import { isDeletedValue } from 'core/Form';
import {
  RoundReadQuery,
  RoundUpdateMutation,
  RoundCreateMutation,
} from './query';

const convertToUnix = (value) => {
  const date = moment(value, 'DD/M/YYYY hh:mm a').unix();
  return date;
};

function isTBC(match) {
  if (match?.teams?.team_a?.hashkey?.toLowerCase() === 'tbc'
     || match?.teams?.team_b?.hashkey?.toLowerCase() === 'tbc'
      || match?.teams?.team_a?.team?.toLowerCase() === 'tbc'
     || match?.teams?.team_b?.team?.toLowerCase() === 'tbc') {
    return true;
  }

  return false;
}

function RoundTeamValidator() {
  const validateTeam = (value, context) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const groupkey = params.get('group');
    if (groupkey) {
      let matches = Object.values(value).filter((group) => group.key === groupkey);
      matches = objectAsArray(matches?.[0]?.matches);
      if (matches) {
        for (let i = 0; i < matches.length; i += 1) {
          if (!isDeletedValue(matches[i]) && !isTBC(matches[i])
            && matches[i]?.teams?.team_a?.hashkey === matches[i]?.teams?.team_b?.hashkey
              && matches[i]?.teams?.team_a?.hashkey !== null
              && matches[i]?.teams?.team_b?.hashkey !== null) {
            return context.createError({ message: `TeamA and TeamB are same on row ${i + 1}` });
          }
        }
      }
    }

    return true;
  };

  const validateMatchTeam = (value, context) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const matchesTeam = {};
    const groupkey = params.get('group');
    if (groupkey) {
      let matches = Object.values(value).filter((group) => group.key === groupkey);
      matches = objectAsArray(matches?.[0]?.matches);
      if (matches) {
        for (let i = 0; i < matches.length; i += 1) {
          if (!isDeletedValue(matches[i]) && !isTBC(matches[i])
           && (matches[i]?.teams?.team_a?.hashkey || matches[i]?.teams?.team_b?.hashkey)
            && matches[i]?.start_at) {
            if (!(matches[i]?.start_at in matchesTeam)) {
              matchesTeam[matches[i]?.start_at] = {};
            }
            if ((matches[i]?.teams?.team_a?.hashkey in matchesTeam[matches[i]?.start_at])) {
              const teamInfo = matchesTeam[
                matches[i]?.start_at
              ]?.[matches[i]?.teams?.team_a?.hashkey];
              return context.createError({ message: `Team A  in match  ${i + 1} is already playing  in same date  with other team on match ${teamInfo.index + 1}` });
            }
            if ((matches[i]?.teams?.team_b?.hashkey in matchesTeam[matches[i]?.start_at])) {
              const teamInfo = matchesTeam[
                matches[i]?.start_at
              ]?.[matches[i]?.teams?.team_b?.hashkey];
              return context.createError({ message: `Team B in match ${i + 1} is already playing in same date with other team on match ${teamInfo.index + 1}` });
            }
            matchesTeam[matches[i]?.start_at] = {
              ...matchesTeam[matches[i]?.start_at],
              [matches[i]?.teams?.team_a?.hashkey]: {
                index: i,
                teamSide: 'A',
              },
              [matches[i]?.teams?.team_b?.hashkey]: {
                index: i,
                teamSide: 'B',
              },
            };
          }
        }
      }
    }
    return true;
  };

  return Yup.mixed()
    .test(
      'round-team-validate',
      validateTeam,
    )
    .test(
      'validate-match-team',
      validateMatchTeam,
    );
}

export const TournamentRoundResource = Resource({
  resourceId: 'tournamentRound',
  name: 'TournamentRound',
  storeId: 'TournamentRound',
  pageKey: 'sports.cricket.tournament',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'TournamentRound',
      shape: Yup.object().shape({
        name: Yup.string().required(),
        groups: RoundTeamValidator(),
      }),
      viewKey: 'tournament_round',
      historyKey: 'TournamentRoundTournamentRound',
      referenceFields: ['tournament_round'],
    }),
    ColumnSet({
      name: 'LegacyData',
      shape: Yup.object().shape({
        key: Yup.string().nullable(),
      }),
      viewKey: 'legacy_data',
      historyKey: 'TournamentRoundLegacyData',
    }),
  ],
  listPrimaryActions: [
    { action: 'rounds', page: 'update.rounds', name: 'Edit Round' },
  ],
  queries: {
    read: QueryAction({
      query: RoundReadQuery,
      resourceNamePath: 'item.tournament_round.name',
      responsePath: 'sports_cricket_tournament_round_read',
      resourcePath: 'item.resource.hashkey',
    }),
  },
  mutations: {
    updateRound: MutationAction({
      mutation: RoundUpdateMutation,
      cs: ['tournament_round', 'legacy_data'],
      responsePath: 'sports_cricket_tournament_round_update',
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        if (resp.tournament_round && resp.tournament_round.groups) {
          resp.tournament_round.groups = arrayAsObject(resp.tournament_round.groups);
        }
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.tournament_round.groups = objectAsArray(resp.tournament_round.groups);
        const postVariables = {
          resource: {
            // eslint-disable-next-line no-underscore-dangle
            _hashkey: data._hashkey,
          },
          tournamentRound: {
            ...resp.tournament_round,
            groups:
              resp.tournament_round.groups
                ? objectAsArray(resp.tournament_round.groups).filter(
                  (group) => group !== null,
                ).map((group) => (
                  {
                    key: group.key || '-',
                    name: group.name || null,
                    index: group.index > 0 ? group.index : 0,
                    teams: objectAsArray(group.teams) || [],
                    is_group_published: group?.is_group_published,
                    matches: group.matches ? objectAsArray(group.matches).map((match) => {
                      return {
                        ...match,
                        stadium: {
                          _hashkey: match && match.stadium && match.stadium.hashkey,
                        } || null,
                        teams: {
                          team_a: {
                            _hashkey: (match && match.teams
                              && match.teams.team_a && match.teams.team_a.hashkey
                              ? match.teams.team_a.hashkey : match.teams.team_a) || null,
                          },
                          team_b: {
                            _hashkey: (match && match.teams
                              && match.teams.team_b && match.teams.team_b.hashkey
                              ? match.teams.team_b.hashkey : match.teams.team_b) || null,
                          },
                        } || {},
                        sub_title: match.sub_title ? match.sub_title : '',
                        status: match.status ?? 'NotStarted',
                        published: group.is_group_published,
                        resource: match && match.resource && match.resource.hashkey
                          ? { _hashkey: match.resource.hashkey }
                          : {
                            account: getAccountKey(),
                            sport: getSport(),
                            key: '-',
                          },
                      };
                    }) : [],
                  }
                )) : [],
          },
        };
        return postVariables;
      },
    }),
    updateMatch: MutationAction({
      mutation: RoundUpdateMutation,
      cs: ['tournament_round'],
      responsePath: 'sports_cricket_tournament_round_update',
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        const tournamentResponse = {
          tournament_round: {
            ...resp.tournament_round,
            groups: resp?.tournament_round?.groups?.map((group) => ({
              ...group,
              matches: arrayAsObject(group.matches),
            })),
          },
        };
        return tournamentResponse;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.tournament_round.groups = objectAsArray(resp.tournament_round.groups);
        const postVariables = {
          resource: {
            // eslint-disable-next-line no-underscore-dangle
            _hashkey: data._hashkey,
          },
          tournamentRound: {
            ...resp.tournament_round,
            groups:
              resp.tournament_round.groups
                ? objectAsArray(resp.tournament_round.groups).filter(
                  (group) => group !== null,
                ).map((group) => (
                  {
                    key: group.key || '-',
                    name: group.name || null,
                    index: group.index > 0 ? group.index : 0,
                    teams: objectAsArray(group.teams) || [],
                    is_group_published: group.is_group_published,
                    matches: objectAsArray(group.matches)
                        && objectAsArray(group.matches).length > 0
                      ? objectAsArray(group.matches).map((match) => {
                        return {
                          start_at: (match.start_at && typeof match.start_at === 'string')
                            ? convertToUnix(match.start_at) : (match.start_at || null),
                          teams: {
                            team_a: {
                              _hashkey: (match && match.teams
                                && match.teams.team_a && match.teams.team_a.hashkey
                                ? match.teams.team_a.hashkey : match.teams.team_a) || null,
                            },
                            team_b: {
                              _hashkey: (match && match.teams
                                && match.teams.team_b && match.teams.team_b.hashkey
                                ? match.teams.team_b.hashkey : match.teams.team_b) || null,
                            },
                          } || {},
                          sub_title: match.sub_title ? match.sub_title : '',
                          internal_name: match.internal_name ? match.internal_name : '',
                          status: match.status ?? 'NotStarted',
                          published: group.is_group_published,
                          notes: match.notes ?? null,
                          stadium: {
                            _hashkey: match && match.stadium && match.stadium.hashkey,
                          } || null,
                          resource: match && match.resource && match.resource.hashkey
                            ? { _hashkey: match.resource.hashkey }
                            : {
                              account: getAccountKey(),
                              sport: getSport(),
                              key: '-',
                            },
                        };
                      }) : [],
                  }
                )) : [],
          },
        };
        return postVariables;
      },
    }),
    create: MutationAction({ mutation: RoundCreateMutation }),
  },
});
