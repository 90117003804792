import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const CmsStructureCreateHintQuery = gql`
query CmsStructureCreateHintQuery($name: String!) {
  cms_structure_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        name
        store_type
      }
    }
    key_suggestions
  }
}
`;

export const CmsStructureCreateMutation = gql`
mutation CmsStructureCreate(
  $name: CmsStructureNameInput!,
  $key: String,
) {
  cms_structure_create(
    name: $name,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const StructureFields = `
resource {
  account
  key
  hashkey: _hashkey
}
name { name, store_type }
structure {
  name
  groups {
    key
    fields {
      key
      editor_type
      store_type
      required
      is_list
      searchable
      hide_on_edit
      related_field_path
      display_type
      display_settings {
        html_tag
        html_class
      }
      label
      primary_index
      title_index
      subtitle_index
      default_value
      source_def {
        typ
        structure {
          account
          key
          hashkey: _hashkey
        }
        action {
          key
          default_vals { key, value }
        }
        url {
          url
          method
          headers { key, value }
          is_form_post
          body
          body_raw
          params {
            key
            store_type
            name
            default_val
            options { key, value }
            required
            location
          }
        }
        include_with_parent
      }
      options {
        name
        value
      }
      options_variable
    }
    label
    key_prefix
    hide_on_edit
  }
  store_type
  active
}
`;

export const CmsStructureReadQuery = gql`
query CmsStructureReadQuery($resource: CmsStructureInput!, $withHistory: Boolean) {
  cms_structure_read(resource: $resource, with_history: $withHistory) {
    item {
      ${StructureFields} 
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const CmsStructureStoreTypeQuery = gql`
query CmsStructureStoreTypeQuery($resource: CmsStructureInput!) {
  cms_structure_read(resource: $resource) {
    item {
      structure {
        store_type 
      }
    }
  }
}
`;

export const CmsStructureUpdateMutation = gql`
mutation CmsStructureUpdateMutation(
  $resource: CmsStructureInput!,
  $structure: CmsStructureStructureInput!,
  $version: RecordStage) {
  cms_structure_update(
    resource: $resource,
    structure: $structure,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const CmsStructureDeleteMutation = gql`
mutation  CmsStructureDeleteMutation(
  $resource: CmsStructureInput!,
  $name: String!) {
  cms_structure_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const CmsStructureSearchQuery = gql`
query CmsStructureSearchQuery($search: String!, $page: PagedRequestInput) {
  cms_structure_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        name
        store_type
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const CmsStructureMenuQuery = gql`
query CmsStructureMenuQuery {
  cms_structure_content_menu {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        name
        store_type
      }
    }
  }
}
`;

export const CmsStructureNameQuery = gql`
query CmsStructureNameQuery($resource: CmsStructureInput!) {
  cms_structure_name(resource: $resource) {
    name
  }
}
`;

export const CmsDSList = gql`
query CmsDSList ($app: String!) {
  cms_ds_list(app: $app) {
    items {
      key
      name
      help_text
    }
  }
}
`;

export const CmsDSDef = gql`
  query CmsDSDef($actionKey: String!) {
    cms_ds_def(action_key: $actionKey) {
      item {
      fields {
        name
        typ
        default_val
        options
        required
        is_list
        is_obj
        is_resource
        resource_name
        fields {
          name
          typ
          default_val
          options
          required
          is_list
          is_obj
          is_resource
          resource_name
          fields {
            name
            typ
            default_val
            options
            required
            is_list
            is_obj
            is_resource
            resource_name
            fields {
              name
              typ
              default_val
              options
              required
              is_list
              is_obj
              is_resource
              resource_name
              fields {
                name
                typ
                default_val
                options
                required
                is_list
                is_obj
                is_resource
                resource_name
                fields {
                  name
                  typ
                  default_val
                  options
                  required
                  is_list
                  is_obj
                  is_resource
                  resource_name
                  fields {
                    name
                    typ
                    default_val
                    options
                    required
                    is_list
                    is_obj
                    is_resource
                    resource_name
                    fields {
                      name
                      typ
                      default_val
                      options
                      required
                      is_list
                      is_obj
                      is_resource
                      resource_name
                      fields {
                        name
                        typ
                        default_val
                        options
                        required
                        is_list
                        is_obj
                        is_resource
                        resource_name
                        fields {
                          name
                          typ
                          default_val
                          options
                          required
                          is_list
                          is_obj
                          is_resource
                          resource_name
                          fields {
                            name
                            typ
                            default_val
                            options
                            required
                            is_list
                            is_obj
                            is_resource
                            resource_name
                            fields {
                              name
                              typ
                              default_val
                              options
                              required
                              is_list
                              is_obj
                              is_resource
                              resource_name
                              fields {
                                name
                                typ
                                default_val
                                options
                                required
                                is_list
                                is_obj
                                is_resource
                                resource_name
                                fields {
                                  name
                                  typ
                                  default_val
                                  options
                                  required
                                  is_list
                                  is_obj
                                  is_resource
                                  resource_name
                                  fields {
                                    name
                                    typ
                                    default_val
                                    options
                                    required
                                    is_list
                                    is_obj
                                    is_resource
                                    resource_name
                                    fields {
                                      name
                                      typ
                                      default_val
                                      options
                                      required
                                      is_list
                                      is_obj
                                      is_resource
                                      resource_name
                                      fields {
                                        name
                                        typ
                                        default_val
                                        options
                                        required
                                        is_list
                                        is_obj
                                        is_resource
                                        resource_name
                                          fields {
                                          name
                                          typ
                                          default_val
                                          options
                                          required
                                          is_list
                                          is_obj
                                          is_resource
                                          resource_name
                                            fields {
                                              name
                                              typ
                                              default_val
                                              options
                                              required
                                              is_list
                                              is_obj
                                              is_resource
                                              resource_name
                                              fields {
                                                name
                                                typ
                                                default_val
                                                options
                                                required
                                                is_list
                                                is_obj
                                                is_resource
                                                resource_name
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
