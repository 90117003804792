import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  SportsPinResourceCreateMutation,
  SportsPinResourceDeleteMutation,
  SportsPinResourceReadQuery,
} from 'sports/query';
// import { resourceHashkey } from 'lib/utils';
import { ReactComponent as PinForUserIcon } from 'assets/icons/icon-pin-for-user.svg';
import { ReactComponent as UnPinForUserIcon } from 'assets/icons/icon-unpin-for-user.svg';
import { ReactComponent as PinForAllIcon } from 'assets/icons/icon-pin-for-everyone.svg';
import { ReactComponent as UnPinForAllIcon } from 'assets/icons/icon-unpin-for-everyone.svg';
import { asRem } from 'lib/css';
import { StatusError, StatusLoading } from 'core/Status';
import { MutationButton } from 'core/Button';

const PinActionsWrapper = styled.div`
  button {
    display: block;
    width: 100%;
    margin-bottom: ${asRem(12)};
  }

  &.pin-actions-icon {
    display: flex;
    align-items: center;
    button {
      border: none;
      width: max-content;
    }
  }
`;

export function PinButton({
  create,
  noBorder,
  resourceType,
  resourceKey,
  resourceName,
  imageUrl,
  userLevel,
  renderIcon = false,
  showLoadingIcon = false,
}) {
  const params = {
    resourceType,
    resourceKey,
    resourceImageUrl: imageUrl,
  };

  const [isCreate, setIsCreate] = useState(create);

  if (isCreate) {
    params.resourceName = resourceName;
  }

  const responseKey = isCreate ? 'sports_pin_resource_create.status' : 'sports_pin_resource_delete.status';
  let name = '';
  if (renderIcon) {
    if (userLevel) {
      name = isCreate ? <PinForUserIcon /> : <UnPinForUserIcon />;
    } else {
      name = isCreate ? <PinForAllIcon /> : <UnPinForAllIcon />;
    }
  } else {
    name = isCreate ? 'Pin' : 'Unpin';
    if (!userLevel) {
      name = isCreate ? 'Pin for everyone' : 'Unpin for everyone';
    }
  }

  if (isCreate) {
    return (
      <MutationButton
        className={`plain ${noBorder ? '' : 'bordered'}`}
        mutation={SportsPinResourceCreateMutation}
        responseKey={responseKey}
        onSuccess={() => setIsCreate(false)}
        name={name}
        variables={
          {
            ...params,
            accountLevel: !userLevel,
            userLevel,
          }
        }
        showLoadingIcon={showLoadingIcon}
      />
    );
  }

  return (
    <MutationButton
      className={`plain ${noBorder ? '' : 'bordered'}`}
      mutation={SportsPinResourceDeleteMutation}
      responseKey={responseKey}
      onSuccess={() => setIsCreate(true)}
      name={name}
      variables={
        {
          ...params,
          accountLevel: !userLevel,
          userLevel,
        }
      }
      showLoadingIcon={showLoadingIcon}
    />
  );
}

PinButton.propTypes = {
  create: PropTypes.bool.isRequired,
  noBorder: PropTypes.bool,
  userLevel: PropTypes.bool.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceKey: PropTypes.any,
  imageUrl: PropTypes.string,
  resourceName: PropTypes.string,
  renderIcon: PropTypes.bool,
  showLoadingIcon: PropTypes.bool,
};

export function PinActions({
  resourceType, resourceKey, resourceName, imageUrl,
  renderIcon, showLoadingIcon = false,
}) {
  // const hashedKey = resourceHashkey({ key: resourceKey });
  const hashedKey = resourceKey;
  const {
    loading,
    error,
    data,
  } = useQuery(SportsPinResourceReadQuery, {
    variables: {
      resourceType,
      resourceKey: hashedKey,
    },
  });

  const resp = data && data.sports_pin_resource_read;
  const userLevel = resp && resp.user_level;
  const accountLevel = resp && resp.account_level;
  const params = {
    resourceType,
    resourceKey: hashedKey,
    resourceName,
    imageUrl,
    renderIcon,
  };

  return (
    <PinActionsWrapper className={renderIcon ? 'pin-actions-icon' : 'pin-actions'}>
      {loading && (<StatusLoading showLoadingIcon={showLoadingIcon} />)}
      {error && (<StatusError error={error} />)}
      {resp && (
        <>
          {userLevel
            ? (<PinButton create={false} userLevel showLoadingIcon={showLoadingIcon} {...params} />)
            : (<PinButton create userLevel showLoadingIcon={showLoadingIcon} {...params} />)}
          {accountLevel
            ? (
              <PinButton
                create={false}
                showLoadingIcon={showLoadingIcon}
                userLevel={false}
                {...params}
              />
            )
            : (
              <PinButton
                create
                userLevel={false}
                showLoadingIcon={showLoadingIcon}
                {...params}
              />
            )}
        </>
      )}
    </PinActionsWrapper>
  );
}

PinActions.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceKey: PropTypes.any,
  resourceName: PropTypes.string,
  icon: PropTypes.bool,
  imageUrl: PropTypes.string,
  renderIcon: PropTypes.bool,
  showLoadingIcon: PropTypes.bool,
};
