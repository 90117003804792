import { capitilise } from 'lib/utils';
import { matchPath } from 'react-router-dom';

function defaultResourcePages() {
  return {
    index: {
      meta: { path: ' ', name: 'Dashboard' },
    },
    list: {},
    create: {},
    read: {
      meta: { path: ':key', name: 'Resource' },
    },
    versions: {
      meta: { path: ':key/versions', name: 'Versions' },
    },
    update: {
      meta: { path: ':key/update', name: 'Update' },
    },
    updateSettings: {
      meta: { path: ':key/updateSettings', name: 'Update' },
    },
    preview: {
      meta: { path: ':key/preview', name: 'Preview' },
    },
  };
}

export const Pages = {
  sports: {
    association: {
      ...defaultResourcePages(),
      updateStatus: {
        review: {},
        meta: { path: ':key/updateStatus', name: 'Update Status' },
      },
      updateImage: {
        review: {},
        meta: { path: ':key/updateImage', name: 'Update Image' },
      },
    },
    region: {
      ...defaultResourcePages(),
      updateStatus: {
        review: {},
        meta: { path: ':key/updateStatus', name: 'Update Status' },
      },
    },
    team: {
      ...defaultResourcePages(),
      updateStatus: {
        review: {},
        meta: { path: ':key/updateStatus', name: 'Update Status' },
      },
      updateImage: {
        review: {},
        meta: { path: ':key/updateImage', name: 'Update Image' },
      },
    },
    stadium: {
      ...defaultResourcePages(),
    },
    category: {
      ...defaultResourcePages(),
    },
    club: {
      ...defaultResourcePages(),
    },
    cricket: {
      competition: {
        ...defaultResourcePages(),
        updateStatus: {
          review: {},
          meta: { path: ':key/updateStatus', name: 'Update Status' },
        },
      },
      tour: {
        ...defaultResourcePages(),
      },
      seasonTeam: {
        read: {
          meta: { path: ':key', name: 'Resource' },
        },
        versions: {
          meta: { path: ':key/versions', name: 'Versions' },
        },
        update: {
          meta: { path: ':key/update', name: 'Update' },
        },
      },
      tournament: {
        ...defaultResourcePages(),
        update: {
          meta: { path: ':key/update', name: 'Update' },
          basic: {},
          teams: {},
          status: {},
          rounds: {},
          playerRoles: {},
          players: {},
          image: {},
          results: {
            meta: { path: 'results', name: 'Results' },
            versions: {
              meta: { path: 'versions', name: 'Versions' },
            },
          },
          rules: {
            meta: { path: 'rules', name: 'Rules' },
            versions: {
              meta: { path: 'versions', name: 'Versions' },
            },
          },
        },
        teams: {
          meta: { path: ':key/teams' },
          updateTeam: {
            review: {},
            meta: { path: 'updateTeam', name: 'Update Team' },
          },
          updateStatus: {
            review: {},
            meta: { path: 'updateStatus', name: 'Update Status' },
          },
          versions: {
            meta: { path: 'versions', name: 'Versions' },
          },
        },
        import: {
          list: {},
          preview: {
            meta: { path: ':key/preview', name: 'Preview' },
          },
          review: {
            meta: { path: ':key/review', name: 'Review' },
          },
          status: {
            meta: { path: ':key/status', name: 'Review' },
          },
          allImports: {},
        },
        rounds: {
          meta: { path: ':key/rounds' },
          updateRound: {
            review: {},
            meta: { path: 'updateRound', name: 'Update Round' },
          },
          updateMatch: {
            review: {},
            meta: { path: 'updateMatch', name: 'Update Match' },
          },
          versions: {
            meta: { path: 'versions', name: 'Versions' },
          },
        },
      },
      match: {
        ...defaultResourcePages(),
        update: {
          meta: { path: ':key/update', name: 'Update' },
          rules: {},
          match: {},
          toss: {},
          squad: {},
          sessions: {},
          play: {},
          reward: {},
          review: {},
          result: {},
          umpire: {},
          advancedSettings: {},
          innings: {
            ball: {
              meta: { path: ':innings/:index', name: 'Ball' },
              update: {
                meta: { path: 'update', name: 'Ball Update' },
              },
              versions: {
                meta: { path: 'versions', name: 'Versions' },
              },
            },
            versions: {
              meta: { path: ':key/versions', name: 'Versions' },
            },
          },
          versions: {
            meta: { path: 'versions', name: 'Versions' },
          },
        },
        innings: {
          ...defaultResourcePages(),
        },
      },
      players: {
        ...defaultResourcePages(),
        duplicatePlayerList: {},
        updateStatus: {
          review: {},
          meta: { path: ':key/updateStatus', name: 'Update Status' },
        },
        updateImage: {
          review: {},
          meta: { path: ':key/updateImage', name: 'Update Image' },
        },
        updateBasicData: {
          review: {},
          meta: { path: ':key/updateBasicData', name: 'Update Basic' },
        },
        updateDuplicate: {
          review: {},
          meta: { path: ':key/updateDuplicate', name: 'Edit Duplicate' },
        },
      },
      person: {
        ...defaultResourcePages(),
        updateImage: {
          review: {},
          meta: { path: ':key/updateImage', name: 'Update Image' },
        },

      },
      ranking: {
        ...defaultResourcePages(),
        updateTeam: {
          meta: { path: ':key/updateTeam', name: 'Update TeamRanking' },
        },
        updatePlayer: {
          meta: { path: ':key/updatePlayer', name: 'Update PlayerRanking' },
        },

        teamRankingVersion: {
          meta: { path: ':key/versions', name: 'Versions' },
        },
        playerRankingVersion: {
          meta: { path: ':key/versions', name: 'Versions' },
        },
      },
      meta: { breadcrumb: false },
    },
    tags: {},
    props: {},
  },
  account: {
    picker: {},
    settings: {
      meta: { path: ':key/settings' },
      members: {
        meta: { path: 'members', name: 'Members' },
      },
      roanuzMode: {
        meta: { path: 'roanuzMode', name: 'Roanuz Mode' },
      },
      liveCollaborators: {
        meta: { path: 'liveCollaborators', name: 'Live Collaborators' },
      },
      dataProviders: {
        meta: { path: 'dataProviders', name: 'Data Providers' },
      },
      cricketSettings: {
        meta: { path: 'cricketSettings', name: 'Cricket App Settings' },
      },
      fantasySettings: {
        meta: { path: 'fantasySettings', name: 'Fantasy App Settings' },
      },
      pushConfiguration: {
        meta: { path: 'pushConfiguration', name: 'Push Configuration' },
      },
      dashboard: {
        meta: { path: 'dashboard', name: 'AccountSettings' },
      },
      search: {
        meta: { path: 'search', name: 'Search' },
      },
      accountFeatures: {
        meta: { path: 'accountFeatures', name: 'Account Features' },
      },
      applicationAccess: { meta: { path: 'applicationAccess', name: 'Application Access' } },
    },
    activeUsers: {},
  },
  admin: {
    dashboard: {},
    users: {},
    accounts: {},
    dataProviders: {},
    create: {},
  },
  pages: {
    onLoginCallback: {},
  },
  match: {
    list: {},
    index: {
      meta: { path: ' ', name: 'Dashboard', breadcrumb: false },
    },
    read: {
      meta: { path: ':key/updateCredits', name: 'Update Versions' },
    },
    creditVersions: {
      meta: { path: ':key/creditVersions', name: 'Credit Versions' },
    },
    roleVersions: {
      meta: { path: ':key/roleVersions', name: 'Role Versions' },
    },
    updateCredits: {
      meta: { path: ':key/updateCredits', name: 'Update Credits' },
    },
    updateRoles: {
      meta: { path: ':key/updateRoles', name: 'Update Roles' },
    },
  },
  fantasy: {
    match: {
      list: {},
      index: {
        meta: { path: ' ', name: 'Dashboard', breadcrumb: false },
      },
      // read: {
      //   meta: { path: ':key', name: 'Resource' },
      // },
      creditVersions: {
        meta: { path: ':key/creditVersions', name: 'Credit Versions' },
      },
      roleVersions: {
        meta: { path: ':key/roleVersions', name: 'Role Versions' },
      },
      updateCredits: {
        meta: { path: ':key/updateCredits', name: 'Update Credits' },
      },
      updateRoles: {
        meta: { path: ':key/updateRoles', name: 'Update Roles' },
      },
    },
    curation: {
      ...defaultResourcePages(),

      read: {
        meta: { path: ':key/update', name: 'update' },
      },
    },
    wizard: {
      index: {
        meta: {
          path: ' ', name: 'Dashboard', breadcrumb: false,
        },
      },
    },
  },
  user: {
    meta: { path: 'user' },
    invites: {
      meta: { path: 'invites', name: 'Pending Invites' },
    },
    dashboard: {
      meta: { path: 'dashboard', name: 'User Settings' },
    },
    forgotpassword: { meta: { path: 'forgot-password', name: 'forgot_password' } },

    passwordChanged: { meta: { path: 'forgot-password/changedSucessfully', name: 'password_Changed' } },
  },
  allActivity: {
    meta: { path: 'all-activity/:topic', name: 'All Activiy' },
  },
  dev: {
    form: {},
  },
  ecom: {
    category: {
      ...defaultResourcePages(),
    },
    inventory: {
      ...defaultResourcePages(),
    },
    price: {
      ...defaultResourcePages(),
    },
    product_kind: {
      ...defaultResourcePages(),
    },
    product_specgroup: {
      ...defaultResourcePages(),
    },
    product_spec: {
      ...defaultResourcePages(),
    },
    product: {
      ...defaultResourcePages(),
    },
  },
  cms: {
    website: {
      ...defaultResourcePages(),
    },
    webtree: {
      ...defaultResourcePages(),
      updatePath: {
        meta: { path: ':key/updatePath', name: 'Rename Path' },
      },
    },
    page: {
      ...defaultResourcePages(),
    },
    structure: {
      ...defaultResourcePages(),
    },
    image: {
      ...defaultResourcePages(),
    },
    linklist: {
      ...defaultResourcePages(),
    },
    structure_content: {
      ...defaultResourcePages(),
      sc: {
        meta: { path: 'sc/:sc', name: 'StructureContent' },
        ...defaultResourcePages(),
      },
    },
  },
};

function resolvePages(pageKey, page, parent) {
  const meta = page.meta || {};
  const parentDataPath = (parent && parent.dataPath) ? `${parent.dataPath}.` : '';
  const parentUrl = parent ? `${parent.url}/` : '';
  let { path, name } = meta;

  if (!path) {
    switch (pageKey) {
      case 'index':
        path = '';
        name = parent ? parent.name : 'index';
        break;
      case 'update':
        path = ':key/update';
        break;
      default:
        path = pageKey;
        break;
    }
  }

  const url = `${parentUrl}${path}`;
  const dataPath = `${parentDataPath}${pageKey}`;
  const newMeta = {
    breadcrumb: true,
    dataPath,
    ...meta,
    name: name || capitilise(pageKey),
    url,
    path,
    starPath: `${path}/*`,
    urlFor(params) {
      let urlPatern = this.url;
      Object.keys(params).forEach((key) => {
        urlPatern = urlPatern.replace(`:${key}`, encodeURIComponent(params[key]));
      });
      return urlPatern;
    },
  };

  // eslint-disable-next-line no-param-reassign
  page.meta = newMeta;

  Object.keys(page).forEach((subPageKey) => {
    if (subPageKey === 'meta') return;
    if (subPageKey !== 'cricket') {
      resolvePages(subPageKey, page[subPageKey], newMeta);
    } else {
      Object.keys(page[subPageKey]).forEach((key) => {
        if (key === 'meta') return;
        resolvePages(key, page[subPageKey][key], newMeta);
      });
    }
  });
}

resolvePages('', Pages, null);

export function breadcrumb(path, parent) {
  const matched = [];
  const resolvedParent = parent || Pages;
  Object.keys(resolvedParent).forEach((pageKey) => {
    if (pageKey === 'meta') return;
    if (pageKey !== 'cricket') {
      const page = resolvedParent[pageKey];
      const match = matchPath(`${page.meta.url}*`, path);
      // const matchFull = matchPath(page.meta.url, path);
      if (match) {
        if (page.meta.breadcrumb) {
          matched.push({
            page,
            match,
          });
        }
        matched.push(...breadcrumb(path, page));
      }
    } else {
      Object.keys(resolvedParent[pageKey]).forEach((key) => {
        if (key === 'meta') return;
        const page = resolvedParent[pageKey][key];
        const match = matchPath(`${page.meta.url}*`, path);
        // const matchFull = matchPath(page.meta.url, path);
        if (match) {
          if (page.meta.breadcrumb) {
            matched.push({
              page,
              match,
            });
          }
          matched.push(...breadcrumb(path, page));
        }
      });
    }
  });
  return matched;
}

export function isActiveParent(path, parent) {
  const resolvedParent = parent || Pages.sports;
  const pageKeys = Object.keys(resolvedParent);
  // eslint-disable-next-line no-restricted-syntax
  for (const pageKey of pageKeys) {
    if (pageKey !== 'meta') {
      const page = resolvedParent[pageKey];
      const match = matchPath(`${page.meta.url}*`, path);

      if (match) {
        return true;
      }

      if (isActiveParent(path, page)) {
        return true;
      }
    }
  }

  return false;
}
