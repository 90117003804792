import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dataFromPath, stripKeys } from 'lib/utils';
import { GetController } from 'core/EditController';
import { AntForm } from 'ants/AntForm';
import { StatusLoading } from 'core/Status';
import { RealtimeEditorConsumer, RealtimeEditorProvider } from 'ants/RealtimeEditorContext';
import { useQueryParam } from 'core/hooks';
import { ErrorBoundary } from 'core/ErrorBoundary';
import { UserContext } from 'core/UserContext';
import { getAccountKey } from 'core/localStore';

export function AntEditorForm({
  item,
  resourceKey,
  resourceName,
  resourceDef,
  updateAction,
  history,
  queryResponse,
  isErrorMode,
  reviewMode,
  formShape,
  versionKey,
  preparePostData,
  realtimeResourceKey,
  renderFields,
  renderSubTitle,
  renderActions,
  renderReviewContent,
  renderFooter,
  renderDeleteAction,
  renderAdditionalLinks,
  showReviewIcons,
  renderResourceImage,
  showPinActions,
  showHistory,
  versionUrl,
  renderCustomError,
  reload,
  secondaryMenuItemHeader,
  enableDelete,
  resourceUrlParams,
  floatSaveBar = true,
  showAdditionalActions,
  showFloatAndTitleBar,
  disableSaveAction,
  ...formProps
}) {
  return (
    <RealtimeEditorProvider
      resourceDef={resourceDef}
      action={updateAction}
      isMutation
      reviewMode={reviewMode}
      resourceKey={realtimeResourceKey ?? resourceKey}
    >
      <RealtimeEditorConsumer>
        {({ receivedRealtimeData, realtimeDisabled, resetRealtime }) => (
          (receivedRealtimeData || realtimeDisabled)
            ? (
              <ErrorBoundary
                renderCustomError={renderCustomError}
                renderAction={() => (
                  <div>
                    <button
                      type="button"
                      className="alert small"
                      onClick={() => {
                        resetRealtime();
                        window.location.reload();
                      }}
                    >
                      Reset draft &amp; Reload
                    </button>
                  </div>
                )}
              >
                <AntForm
                  resourceKey={resourceKey}
                  resourceName={resourceName}
                  realtimeResourceKey={realtimeResourceKey}
                  mutation={updateAction.mutation}
                  action={updateAction}
                  item={item}
                  renderAdditionalLinks={renderAdditionalLinks}
                  renderResourceImage={renderResourceImage}
                  renderReviewContent={renderReviewContent}
                  resourceDef={resourceDef}
                  history={history}
                  queryResponse={queryResponse.item}
                  relatedItem={queryResponse.related ?? null}
                  schema={formShape}
                  isErrorMode={isErrorMode}
                  renderSubTitle={renderSubTitle}
                  responseKey={updateAction.responsePath}
                  responseStatusKey={updateAction.responseStatusPath}
                  prepareVariables={(values, params) => {
                    return preparePostData({
                      ...stripKeys(values),
                      _hashkey: resourceKey,
                      ...params,
                    });
                  }}
                  reload={reload}
                  renderFields={renderFields}
                  renderActions={renderActions}
                  renderDeleteAction={renderDeleteAction}
                  versionKey={versionKey}
                  renderFooter={renderFooter}
                  {...formProps}
                  showHistory={showHistory}
                  showReviewIcons={showReviewIcons}
                  showPinActions={showPinActions}
                  versionUrl={versionUrl}
                  secondaryMenuItemHeader={secondaryMenuItemHeader}
                  enableDelete={enableDelete}
                  floatSaveBar={floatSaveBar}
                  resourceUrlParams={resourceUrlParams}
                  showAdditionalActions={showAdditionalActions}
                  showFloatAndTitleBar={showFloatAndTitleBar}
                  disableSaveAction={disableSaveAction}
                />
              </ErrorBoundary>
            )
            : (<StatusLoading message="Connecting to real time editor" />)
        )}
      </RealtimeEditorConsumer>
    </RealtimeEditorProvider>
  );
}

AntEditorForm.propTypes = {
  item: PropTypes.object,
  reviewMode: PropTypes.bool,
  resourceKey: PropTypes.any,
  versionKey: PropTypes.string,
  resourceName: PropTypes.string,
  realtimeResourceKey: PropTypes.string,
  resourceDef: PropTypes.object,
  updateAction: PropTypes.object,
  history: PropTypes.object,
  formShape: PropTypes.object,
  preparePostData: PropTypes.func,
  renderFields: PropTypes.func,
  renderActions: PropTypes.func,
  renderDeleteAction: PropTypes.func,
  renderFooter: PropTypes.func,
  renderReviewContent: PropTypes.func,
  queryResponse: PropTypes.object,
  isErrorMode: PropTypes.func,
  renderSubTitle: PropTypes.func,
  renderResourceImage: PropTypes.func,
  renderAdditionalLinks: PropTypes.func,
  showReviewIcons: PropTypes.bool,
  showPinActions: PropTypes.bool,
  showHistory: PropTypes.bool,
  renderCustomError: PropTypes.func,
  versionUrl: PropTypes.string,
  reload: PropTypes.bool,
  secondaryMenuItemHeader: PropTypes.object,
  enableDelete: PropTypes.bool,
  floatSaveBar: PropTypes.bool,
  resourceUrlParams: PropTypes.object,
  showAdditionalActions: PropTypes.bool,
  showFloatAndTitleBar: PropTypes.bool,
  disableSaveAction: PropTypes.bool,
};

const AntEditorWrapper = styled.div`
`;

export function AntEditor({
  resourceDef,
  readAction,
  queryVariables,
  updateAction,
  prepareForEdit,
  prepareForSave,
  formShape,
  versionKey,
  renderSubTitle,
  isErrorMode,
  showHistory,
  showReviewIcons,
  showPinActions,
  renderFields,
  renderFooter,
  renderActions,
  renderReviewContent,
  renderDeleteAction,
  renderResourceImage,
  renderAdditionalLinks,
  versionUrl,
  renderCustomError,
  reload,
  showFloatAndTitleBar,
  secondaryMenuItemHeader,
  resourceUrlParams,
  enableDelete = false,
  floatSaveBar = true,
  showAdditionalActions = false,
  ...formProps
}) {
  const { value: reviewMode } = useQueryParam('review', { asBool: true });

  const userContext = useContext(UserContext);
  const accKey = getAccountKey();

  const userRole = userContext.user.accounts.accounts.find((acc) => acc.account.key === accKey);
  const enableDeleteOption = (!userContext.roanuzMode)
  || (userContext.roanuzMode && userRole.role_v2 === 100);

  return (
    <AntEditorWrapper>
      <ErrorBoundary>
        <GetController
          resourceDef={resourceDef}
          query={readAction.query}
          variables={queryVariables ? {
            ...queryVariables,
            ...readAction.variables,
          } : {
            withHistory: true,
            ...readAction.variables,
          }}
          responseKey={readAction.responsePath}
          render={(item) => (
            <AntEditorForm
              item={
              prepareForEdit
                ? prepareForEdit(item)
                : updateAction.prepareForEdit(updateAction, resourceDef, item)
            }
              queryResponse={item}
              resourceKey={dataFromPath(item, readAction.resourcePath)}
              resourceName={dataFromPath(item, readAction.resourceNamePath)}
              resourceDef={resourceDef}
              updateAction={updateAction}
              history={item.history}
              formShape={formShape ?? updateAction.shape(updateAction, resourceDef)}
              preparePostData={(data) => {
                if (prepareForSave) return prepareForSave(data, item);
                return updateAction.prepareForSave(updateAction, resourceDef, data, item);
              }}
              reload={reload}
              renderReviewContent={renderReviewContent}
              renderAdditionalLinks={renderAdditionalLinks}
              renderFields={renderFields}
              isErrorMode={isErrorMode}
              renderResourceImage={renderResourceImage}
              renderSubTitle={renderSubTitle}
              versionKey={versionKey}
              renderActions={renderActions}
              renderDeleteAction={renderDeleteAction}
              renderFooter={renderFooter}
              reviewMode={reviewMode}
              showPinActions={showPinActions}
              showReviewIcons={showReviewIcons}
              showHistory={showHistory}
              versionUrl={versionUrl}
              renderCustomError={renderCustomError}
              secondaryMenuItemHeader={secondaryMenuItemHeader}
              enableDelete={enableDeleteOption || enableDelete}
              floatSaveBar={floatSaveBar}
              resourceUrlParams={resourceUrlParams}
              showAdditionalActions={showAdditionalActions}
              showFloatAndTitleBar={showFloatAndTitleBar}
              {...formProps}
            />
          )}
        />
      </ErrorBoundary>
    </AntEditorWrapper>
  );
}

AntEditor.propTypes = {
  resourceDef: PropTypes.object.isRequired,
  readAction: PropTypes.object.isRequired,
  updateAction: PropTypes.object.isRequired,
  response: PropTypes.object,
  formShape: PropTypes.object,
  prepareForEdit: PropTypes.func,
  prepareForSave: PropTypes.func,
  queryVariables: PropTypes.object,
  renderFields: PropTypes.func,
  versionKey: PropTypes.string,
  renderActions: PropTypes.func,
  renderDeleteAction: PropTypes.func,
  renderReviewContent: PropTypes.func,
  renderAdditionalLinks: PropTypes.func,
  renderFooter: PropTypes.func,
  useFullscreen: PropTypes.bool,
  renderSubTitle: PropTypes.func,
  isErrorMode: PropTypes.func,
  showReviewIcons: PropTypes.bool,
  showPinActions: PropTypes.bool,
  showHistory: PropTypes.bool,
  renderResourceImage: PropTypes.func,
  versionUrl: PropTypes.string,
  renderCustomError: PropTypes.func,
  reload: PropTypes.bool,
  secondaryMenuItemHeader: PropTypes.object,
  resourceUrlParams: PropTypes.object,
  enableDelete: PropTypes.bool,
  floatSaveBar: PropTypes.bool,
  showAdditionalActions: PropTypes.bool,
  showFloatAndTitleBar: PropTypes.bool,
};
