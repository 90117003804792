import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
  // RefField,
} from 'ants/resource';
import { editorMetaCleanUpAndConvert, editorMetaPrepareItem } from 'core/editorMeta';
import { WebTreeImageView } from './ResourceImage';
import {
  WebTreeSearchQuery,
  WebTreeReadQuery,
  WebTreeCreateMutation,
  WebTreeUpdateMutation,
  WebTreeNameQuery,
  WebTreeNodesQuery,
  WebTreeUpdatePathMutation,
} from './query';
import { HeadElementUtils } from './utils';

export const WebTreeResource = Resource({
  resourceId: 'webtree',
  resourceKeys: ['webtree'],
  app: 'cms',
  name: 'WebTree',
  keyVal: 'WebTree',
  topicName: 'WebTree',
  storeId: 'WebTree',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'WebTree',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        variables: Yup.array().of(Yup.object().shape({
          key: StringFieldMinMax(1, 120),
          value: StringFieldMinMax(1, 256),
        })).nullable().default([]),
      }),
    }),
    ColumnSet({
      name: 'Name',
      shape: Yup.object().shape({}),
    }),
    ColumnSet({
      name: 'Props',
      shape: Yup.object().shape({}),
      referenceFields: ['page'],
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<WebTreeImageView item={item} />),
  queries: {
    read: QueryAction({
      query: WebTreeReadQuery,
      resourceNamePath: 'item.webtree.path',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: WebTreeSearchQuery, responsePath: 'cms_webtree_search' }),
    search: QueryAction({
      query: WebTreeSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.path',
    }),
    name: QueryAction({ query: WebTreeNameQuery, resourceNamePath: 'cms_webtree_name.name' }),
    nodes: QueryAction({ query: WebTreeNodesQuery, responsePath: 'cms_webtree_nodes' }),
  },
  mutations: {
    update: MutationAction({
      mutation: WebTreeUpdateMutation,
      cs: ['props'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        // console.log('> Prepare for edit', JSON.stringify(resp));
        if (resp.props.props) {
          const elementKeys = ['head_items', 'body_begin_items', 'body_end_items'];
          elementKeys.forEach((key) => {
            resp.props.props = editorMetaPrepareItem({
              obj: resp.props.props,
              itemsProp: key,
              createFunc: HeadElementUtils.prepareForEdit,
            });
          });
          if (resp.props.props.og) {
            resp.props.props.og = editorMetaPrepareItem({
              obj: resp.props.props.og,
              itemsProp: 'attrs',
            });
          }
          if (resp.props.props.headers) {
            resp.props.props.headers = editorMetaPrepareItem({
              obj: resp.props.props.headers,
              itemsProp: 'headers',
            });
          }
        }
        return resp;
      },
      prepareForSave(self, resource, data) {
        // console.log('Prepare for Save', resource, JSON.stringify(data));
        const resp = self.defaultPrepareForSave(self, resource, data);
        console.log('Prepare for Save', resource, JSON.parse(JSON.stringify(resp)));
        console.log('Prepare for Save Props', JSON.parse(JSON.stringify(resp.props.props)));

        if (resp.props.props) {
          const elementKeys = ['head_items', 'body_begin_items', 'body_end_items'];
          elementKeys.forEach((key) => {
            resp.props.props = editorMetaCleanUpAndConvert({
              obj: resp.props.props,
              itemsProp: key,
              cleanUp: data.version,
              cleanUpFunc: (item) => HeadElementUtils.prepareForSave(item, data.version),
            });
          });
          if (resp.props.props.og) {
            resp.props.props.og = editorMetaCleanUpAndConvert({
              obj: resp.props.props.og,
              itemsProp: 'attrs',
              cleanUp: data.version,
            });
          }

          if (resp.props.props.headers) {
            resp.props.props.headers = editorMetaCleanUpAndConvert({
              obj: resp.props.props.headers,
              itemsProp: 'headers',
              cleanUp: data.version,
            });
          }
        }

        console.log('Prepare for Save Props 2', JSON.parse(JSON.stringify(resp.props.props)));
        return resp;
      },
    }),
    updatePath: MutationAction({
      mutation: WebTreeUpdatePathMutation,
      cs: ['name'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        if (data.version) {
          return {
            newPath: resp.name.newName,
            note: resp.name.note,
          };
        }
        return resp;
      },
    }),
    create: MutationAction({ mutation: WebTreeCreateMutation }),
  },
});
