import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { AppContext, AppLayoutWidth } from 'core/AppContext';
import { Box } from 'core/Box';
import {
  dataFromPath,
  flatObject,
  objectDifference,
  setValueOnPath,
  timestamp,
} from 'lib/utils';
import { mergeViewWithHistoryDraft } from 'ants/ants';
import {
  ResponseStatus,
  StatusError,
  StatusLoading,
} from 'core/Status';
import { UserContext } from 'core/UserContext';
import { Interaction, RealtimeContext } from 'ants/RealtimeContext';
import { RealtimeEditorContext } from 'ants/RealtimeEditorContext';
import { asRem, Breakpoint } from 'lib/css';
// import { ReactComponent as PreviewIcon } from 'assets/icons/icon-preview.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/icon-arrow-right.svg';
import { ReactComponent as ReviewIcon } from 'assets/icons/icon-review.svg';
import { ReactComponent as EditPublishIcon } from 'assets/icons/icon-edit-publish.svg';
import {
  Label12Regular, Display20Bold, Label12Medium,
} from 'style/typography';
import {
  FormContext, Form, FormBoundary,
} from 'core/Form';
import { KeyBox } from 'core/KeyBox';
import { PinActions } from './PinActions';
import { VersionRecordsView } from './DocVersionRecords';
import { SaveToolbar } from './SaveToolbar';

export const FormScreenLayoutStyle = {
  One: 'one',
  Two: 'two', // Full screen without preview
  Three: 'three',
  Four: 'four',
};

function FormChanges({
  onChange,
  // realtimeData,
  realtimeUpdates,
  editSessionId,
  resourceKey,
  initValues,
}) {
  const {
    values,
    isValid,
    dirty,
    isSubmitting,
    setFieldValue,
    resetValidation,
    initValidated,
  } = useContext(FormContext);
  useEffect(() => {
    // console.log('rz/form/change', values);
    onChange({
      values,
      isValid,
      dirty,
      isSubmitting,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isValid, dirty, isSubmitting]);

  const handleIncomingChange = (changes, isLiveUpdate = false) => {
    // console.log('Real time FORM update', isLiveUpdate, changes);
    Object.keys((changes ?? {})).forEach((csPath) => {
      const change = changes[csPath].data;
      if (!change || !change.path) { return; }
      const currentValue = dataFromPath(values, change.path);
      if (currentValue !== change.value) {
        if (!isLiveUpdate) {
          // console.log('Setting form value', change.path, currentValue, change.value);
          setFieldValue(change.path, change.value, true);
        } else if (editSessionId !== change.author.editSessionId) {
          // console.log('Incoming value from ', change.author.key, change.value);
          setFieldValue(change.path, change.value, true);
        }
      }
    });
  };

  useEffect(() => {
    handleIncomingChange(realtimeUpdates, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtimeUpdates]);

  const [initValidatedKey, setInitValidatedKey] = useState(null);
  useEffect(() => {
    if (initValidatedKey !== resourceKey) {
      setInitValidatedKey(resourceKey);
      if (initValidated) {
        resetValidation(initValues);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceKey]);
  return null;
}

FormChanges.propTypes = {
  onChange: PropTypes.func,
  realtimeUpdates: PropTypes.object,
  editSessionId: PropTypes.string,
  resourceKey: PropTypes.string,
  initValues: PropTypes.object,
};

const AntFormWrapper = styled.div`
  &.layout-two {
    // Space for the toolbar
    margin-bottom: ${asRem(120)};

    .form-container {
      .edit-area {
        margin-right: 0;
      }
    }

  }

  &.layout-three, &.layout-four {
    margin-top: ${asRem(40)};
    .review-container {
      position: fixed;
      right: 0;
      top: ${asRem(106)};
      bottom: 0;
      overflow-y: scroll;
      width: ${asRem(400)};
      padding: ${asRem(20)};
      background-color: var(--color-secondary-bg);
      z-index: 999;
    }
    .form-container {
      height: 100%;
      min-height: 100vh;
      display: block;
      margin-bottom: ${asRem(100)};
      max-width: var(--layout-md-max-width);
      .edit-area {
        margin-right: 0;
      }
    }

    .float-bar {
      margin: 0 auto;
      position: sticky;
      bottom: ${asRem(20)};
      z-index: 999;
    }
  }

  &.layout-four {
    .form-container {
      max-width: 100%;
    }
  }

  .title-block {
    margin: 0 auto;
    padding: 0 var(--layout-padding-box) var(--layout-padding-box);

    display: flex;

    .label-12-medium {
      padding-top: ${asRem(8)};
      color: var(--color-text-2);
      display: inline-block;

      .key-box {
        display: flex;
        gap: ${asRem(8)};
      }
    }

    .resource-image-view {
      margin-right: ${asRem(20)};
    }
  }
  .form-container {
    display: block;
    @media screen and (min-width: ${Breakpoint.md}) {
      display: flex;
      justify-content: center;
    }

    /* .rz-box, .rz-toggle-box {
      transition: all 2s ease-out;
    } */

    >.action-area {
      flex: 0 0 ${asRem(260)};

      .rz-box {
        margin-bottom: ${asRem(20)};
      }

      .publish-box {
        padding-bottom: ${asRem(8)};
      }

      .primary-actions-box {
        a {
          display: flex;
          gap: ${asRem(4)};
          justify-content: stretch;
          align-items: center;
          margin-bottom: ${asRem(20)};
          border-width: 0;
          padding: 0;

          ::first-child{
            width: ${asRem(20)};
          }

          div {
            flex: 1;
          }

          ::last-child{
            width: ${asRem(18)};
          }

          svg {
            fill: var(--color-secondary-cta);
          }

          &.edit-link {
            color: var(--color-highlight);
            svg {
              fill: var(--color-highlight);
            }

            &:hover {
              color: var(--color-button-primary-bg);
            }
          }

          &:hover {
            svg {
              fill: var(--color-button-primary-bg);
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .actions-box {
        .delete-action {
          margin-top: ${asRem(16)};
          border-top: 1px solid var(--color-disabled);
          padding-top: ${asRem(16)};

          h5 {
            font-weight: 500;
          }

          p {
            padding-bottom: ${asRem(8)};
            color: var(--color-text-2);
          }

          button {
            display: block;
            width: 100%;
          }
        }
      }

      .versions-box {
        .rz-box-content {
          border-top: none;
          padding: 0;
          margin: 0;
          .record {
            &:first-child {
              .record-container {
                border-top: 1px solid var(--color-border-1);
              }
            }
          }
        }
      }
    }
    >.edit-area {
      @media screen and (min-width: ${Breakpoint.md}) {
        margin-right: ${asRem(28)};
        flex: auto;
      }
      .form-content {
        h3 {
          padding-bottom: var(--layout-padding-box);
        }
        &>div {
          margin-bottom: ${asRem(20)};
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    } 
  }
`;

export function AntForm({
  resourceKey,
  resourceName = null,
  realtimeResourceKey = null,
  initValue = null,
  item,
  resourceDef,
  history,
  title = null,
  schema,
  mutation,
  action,
  responseKey,
  responseStatusKey = null,
  prepareVariables,
  onChange,
  renderSubTitle,
  queryResponse,
  relatedItem,
  onDirty,
  onFormCanSave,
  watchChanges = true,
  renderFields,
  renderFooter,
  renderActions,
  renderAdditionalLinks,
  renderDeleteAction,
  renderReviewContent,
  renderResourceImage,
  isErrorMode,
  versionKey,
  onSaveSuccess,
  showReviewIcons,
  showHistory,
  showPinActions,
  onChangeSet,
  layout = FormScreenLayoutStyle.One,
  setSecondaryMenu,
  showSaveToolbar = true,
  skipLayoutUpdate = false,
  versionUrl,
  reload,
  secondaryMenuItemHeader,
  secondaryMenuItemhashkey,
  enableDelete,
  resourceUrlParams,
  floatSaveBar = true,
  showAdditionalActions,
  showFloatAndTitleBar,
  disableSaveAction,
}) {
  const {
    updateInteraction,
    realtimePath,
    realtimeData,
    realtimeUpdates,
    setFormFieldState,
    resetRealtime,
    topic,
    userColors,
    reviewMode,
    resourceKey: rtKey,
    resetContext,
  } = useContext(RealtimeEditorContext);
  const { deviceKey, user } = useContext(UserContext);
  const appContext = useContext(AppContext);
  const resolvedRealtimeRKey = realtimeResourceKey ?? resourceKey;

  useEffect(() => {
    if (rtKey !== resolvedRealtimeRKey) {
      resetContext();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtKey, resourceKey]);

  // useEffect(() => {
  //   appContext.setLayoutWidth(AppLayoutWidth.Two);
  // });
  useEffect(() => {
    if (skipLayoutUpdate) {
      return;
    }

    if (layout === FormScreenLayoutStyle.Two) {
      appContext.setLayoutWidth(AppLayoutWidth.One);
      appContext.toggleTopMenu({ primary: false, secondary: true });
    } else if (layout === FormScreenLayoutStyle.Three) {
      appContext.setLayoutWidth(AppLayoutWidth.Five);
    } else if (layout === FormScreenLayoutStyle.Four) {
      appContext.setLayoutWidth(AppLayoutWidth.Seven);
    } else {
      appContext.setLayoutWidth(AppLayoutWidth.Two);
    }

    const menuName = `menu-${resourceDef.resourceId}-${resourceKey}-${versionKey}`;
    if (appContext.secondaryMenuName === menuName) {
      return;
    }

    if (setSecondaryMenu) {
      const items = setSecondaryMenu({
        resourceKey: secondaryMenuItemhashkey || resourceKey,
        resource: item,
        queryResponse: secondaryMenuItemHeader || queryResponse,
      });
      if (items !== null) {
        appContext.setSecondaryMenu(menuName, items);
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout]);

  const rtContext = useContext(RealtimeContext);
  useEffect(() => {
    if (rtContext.socketState === 'Connected') {
      rtContext.updateRootInteraction({
        action: action.pageKey,
        resourceKey,
        resourceName,
        isMutation: action.isMutation,
        resourceType: resourceDef.resourceId,
        page: resourceDef.pageFor(resourceDef, action.pageKey)?.meta.dataPath,
        pageParams: {
          key: resourceKey,
          ...action.pageParams(action, item),
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rtContext.socketState,
    resourceDef.resourceId,
    resourceKey,
  ]);

  const mergedView = mergeViewWithHistoryDraft(resourceDef.columnSets, item, history);
  const defaultTitle = resourceName;
  const titleToShow = title || defaultTitle;
  const mergedHistory = mergedView.history;
  const showSidebar = (layout === FormScreenLayoutStyle.One);
  const showTitle = (layout !== FormScreenLayoutStyle.Two);

  const formInitValues = {
    ...(initValue || {}),
    ...JSON.parse(JSON.stringify(mergedView.view)),
  };

  const [changeSet, setChangeSet] = useState({
    init: flatObject({ ...formInitValues }),
    changes: {},
    lastChange: {},
    saved: {},
  });

  Object.keys(realtimeData).forEach((csPath) => {
    if (realtimeData[csPath] && realtimeData[csPath].path && realtimeData[csPath].author) {
      // setValueOnPath(formInitValues, realtimeData[csPath].value, csPath, true);
      try {
        setValueOnPath(formInitValues, realtimeData[csPath].value, csPath, true);
      } catch (e) {
        console.log(
          JSON.parse(JSON.stringify(formInitValues)),
          JSON.parse(JSON.stringify(realtimeData)),
          csPath,
        );
        console.log('Error setting value', e);
        throw e;
      }
    }
  });

  const [editSessionId] = useState(`${user.resource.key}#${deviceKey}#${timestamp()}`);
  const [saveError, setSaveError] = useState(null);
  const [
    doSave,
    { loading, error: mutationError, data },
  ] = useMutation(mutation);

  useEffect(() => {
    setSaveError(mutationError);
  }, [mutationError]);

  const [formChanged, setFormChanged] = useState(false);
  const [formCanSave, setFormCanSave] = useState(false);
  const [capturedValues, setCapturedValues] = useState(null);

  const buildDataState = () => {
    // console.log('Updating Field state');
    const initFlat = flatObject({ ...(initValue || {}) });
    const mergedFlat = flatObject({ ...mergedView.view });
    const itemFlat = flatObject({ ...item });
    // console.log('rz/form/rt/Merged', initFlat, mergedFlat, itemFlat);
    const dataState = {};
    const paths = new Set(Object.keys(initFlat));
    Object.keys(mergedFlat).forEach((k) => paths.add(k));
    Object.keys(itemFlat).forEach((k) => paths.add(k));
    Object.keys(realtimeData).forEach((k) => paths.add(k));
    Object.keys(realtimeUpdates).forEach((k) => paths.add(k));

    const filteredPaths = Array.from(paths).filter((k) => k.indexOf('.__') === -1 && !k.startsWith('_'));

    filteredPaths.forEach((path) => {
      const state = {
        init: { use: false },
        draft: { use: false },
        published: { use: false },
        realtime: { use: false },
      };

      const iVal = initFlat[path];
      const mVal = mergedFlat[path];
      const pVal = itemFlat[path];

      const colHistory = mergedView.history.versions[path.split('.')[0]];

      let rItem = realtimeUpdates[path];
      if (!rItem) {
        rItem = realtimeData[path];
      } else {
        rItem = rItem.data;
      }

      const rVal = rItem ? rItem.value : undefined;

      state.init.value = iVal;
      state.draft.value = (mVal !== pVal) ? mVal : undefined;
      state.published.value = pVal;
      state.realtime.value = rVal;

      if (rVal !== undefined && (rVal !== pVal || rVal !== mVal)) {
        state.realtime = {
          ...rItem,
          use: true,
        };
      } else if (mVal !== undefined && (mVal !== pVal)) {
        let draftAuthor = { first_name: '' };
        if (colHistory && colHistory.draft.authors.length > 0) {
          const draftColAuthor = colHistory.draft.authors[0];
          draftAuthor = {
            first_name: draftColAuthor.name,
            key: draftColAuthor.key,
            ts: draftColAuthor.rs,
          };
        }

        state.draft = {
          value: mVal,
          use: true,
          author: draftAuthor,
        };
      } else if (pVal !== undefined) {
        state.published = {
          value: pVal,
          use: pVal === iVal || pVal === mVal,
          author: { first_name: '' },
        };
      } else if (iVal !== undefined) {
        state.init = {
          value: iVal,
          use: true,
          author: { first_name: '' },
        };
      }

      dataState[path] = state;
    });
    // console.table(dataState);
    return dataState;
  };

  useEffect(() => {
    setFormFieldState(buildDataState());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  // const dataState = buildDataState();
  // console.table(dataState);

  const [activeVersion, setActiveVersion] = useState(null);

  const onSubmit = (values, params, { setSubmitting, formSaved, setFieldValue }) => {
    setSubmitting(true);
    setSaveError(null);
    setActiveVersion(params.version);
    const variables = {
      resource: {
        _hashkey: resourceKey,
        // account: getAccountKey(),
      },
      version: params.version,
      ...prepareVariables(values, params),
    };
    doSave({ variables }).then((resp) => {
      setSubmitting(false);
      if (resp && resp.errors && resp.errors.length > 0) {
        setSaveError(resp.errors[0]);
      }
      const respData = resp && resp.data && dataFromPath(resp.data, responseKey);
      const statusData = respData && dataFromPath(resp.data, responseStatusKey || responseKey);
      if (respData && statusData.okay) {
        formSaved();
        setChangeSet((state) => ({
          ...state,
          saved: flatObject({ ...values }),
        }));
        resetRealtime();
        if (onSaveSuccess) {
          onSaveSuccess(respData, setFieldValue);
        }
        // clearResourceCache(apolloClient, resourceDef.storeId, resourceKey);
      } else {
        console.log('Unknow Save Status', responseKey, responseStatusKey || responseKey, resp);
      }
    }).catch((err) => {
      console.log('Error', err);
      setSubmitting(false);
    });
    return true;
  };

  const pushRealtimeData = (changes) => {
    if (!realtimePath) { return; }
    let focusElement = '';
    if (document && document.activeElement) {
      focusElement = document.activeElement.getAttribute('name') || '';
    }
    const changeData = {};
    let haveUpdate = false;
    changes.changes.forEach((change) => {
      if (change.author && change.author.editSessionId === editSessionId) {
        changeData[change.path] = change;
        haveUpdate = true;
      } else if (!change.author) {
        console.log('Author missing', change);
      }
    });
    if (!haveUpdate) {
      // console.log('rz/rt/No update', changes, editSessionId);
      return;
    }
    // console.log(
    //   'rz/rt/Pushing Real time data',
    //   JSON.parse(JSON.stringify(changes)),
    //   JSON.parse(JSON.stringify(changeData)),
    //   focusElement,
    // );
    updateInteraction({
      topic: realtimePath,
      realtimePath,
      interaction: Interaction({
        kind: 'edit',
        resourceKey,
        action: '',
        focus: focusElement,
      }),
      realtimeData: {
        ...changeData,
      },
    });
  };

  const pushRealTime = (latestChangeSet) => {
    pushRealtimeData(latestChangeSet.lastChange);
  };

  const updateChangeSet = ({
    values,
  }) => {
    const author = {
      key: user.resource.key,
      editSessionId,
      ts: timestamp(),
      firstName: user.profile.first_name,
      url: user.profile.picture_url,
    };
    const serverChangeSet = realtimeData;
    const diffParam = {
      flat: false,
      author,
      changeSet: serverChangeSet,
      includeRemoved: true,
    };

    const latestValues = flatObject({ ...values });

    // console.log(
    //   'rz/rt/Changes',
    //   JSON.parse(JSON.stringify(values)),
    //   JSON.parse(JSON.stringify(latestValues)),
    // );

    const changes = objectDifference(changeSet.init, latestValues, diffParam);
    const lastChange = objectDifference(changeSet.lastChange, latestValues, diffParam);
    const saved = objectDifference(changeSet.saved, latestValues, diffParam);
    const latestChangeSet = {
      init: changeSet.init,
      changes,
      lastChange,
      saved,
    };

    if (onChangeSet) {
      onChangeSet(changeSet);
    }

    if (changes.changes.length > 0) {
      pushRealTime(latestChangeSet);
    } else {
      // console.log('rz/rt/No Changes', latestChangeSet, changes.changes);
    }

    // console.log(
    //   'rz/rt/No Changes',
    //   JSON.parse(JSON.stringify(values)),
    //   JSON.parse(JSON.stringify(latestValues)),
    //   JSON.parse(JSON.stringify(changes.changes)),
    // );

    setChangeSet((state) => ({
      ...state,
      lastChange: latestValues,
    }));

    setFormFieldState(buildDataState());
  };

  const handleFormChange = ({
    values,
    isValid,
    dirty,
    isSubmitting,
  }) => {
    // console.log('rz/form/handle-change', values);
    updateChangeSet({ values });
    if (!isValid) {
      setFormCanSave(false);
      return;
    }

    if (isSubmitting) {
      setFormCanSave(false);
      return;
    }

    const hasWatchFunction = onChange || onDirty || onFormCanSave;

    if ((!hasWatchFunction) && (!watchChanges)) { return; }

    const preparedVariables = prepareVariables(values, {});
    const raw = JSON.stringify(preparedVariables);

    if (formChanged !== dirty) {
      setFormChanged(dirty);
    }
    if (formCanSave !== dirty) {
      setFormCanSave(dirty);
    }

    if (capturedValues !== raw) {
      setCapturedValues(raw);
      if (onChange) {
        onChange({ values, preparedVariables });
      }
    }
  };

  useEffect(() => {
    if (onDirty) {
      onDirty(formChanged);
    }
  }, [formChanged, onDirty]);

  useEffect(() => {
    if (onFormCanSave) {
      onFormCanSave(formCanSave);
    }
  }, [onFormCanSave, formCanSave]);

  const response = dataFromPath(data, responseStatusKey || responseKey);
  const resourceUrl = resourceDef.pageFor(resourceDef, 'read')?.meta.urlFor({ key: resourceKey, ...(resourceUrlParams || {}) } || '');
  const errorMode = isErrorMode(queryResponse);

  const classes = [];

  if (layout) {
    classes.push(`layout-${layout}`);
  }

  // console.log('RZ/from/init/', formInitValues, JSON.parse(JSON.stringify(item)));

  useEffect(() => {
    if (response && reload) {
      window.location.reload();
    }
  }, [reload, response]);

  return (
    <AntFormWrapper className={classes.join(' ')}>
      <FormBoundary
        initialValues={formInitValues}
        validationSchema={schema}
        validationContext={{
          queryResponse,
          relatedItem,
        }}
        onSubmit={onSubmit}
        validateOnChange
        validateOnBlur
        validateOnMount
        enableReinitialize
        // initValidated={initValidated}
      >
        {({
          values,
          setFieldValue,
          submitForm,
          isSubmitting,
          errors,
          isValid,
        }) => (
          <Form>
            <FormChanges
              onChange={handleFormChange}
              realtimeData={realtimeData}
              realtimeUpdates={realtimeUpdates}
              editSessionId={editSessionId}
              resourceKey={resourceKey}
              initValues={formInitValues}
            />
            {((showTitle && layout !== FormScreenLayoutStyle.Three
            && layout !== FormScreenLayoutStyle.Four) || showFloatAndTitleBar) && (
              <div className="title-block">
                {renderResourceImage && renderResourceImage(queryResponse)}
                <div>
                  <Display20Bold>
                    {resourceUrl ? (
                      <Link className="clear" to={resourceUrl}>
                        {titleToShow}
                      </Link>
                    ) : <span>{titleToShow}</span>}
                  </Display20Bold>
                  {renderSubTitle ? renderSubTitle({ queryResponse })
                    : (
                      <Label12Medium>
                        {resourceUrl ? (
                          <Link className="clear key-box" to={resourceUrl}>
                            {'Key: '}
                            <KeyBox resource={resourceKey} />
                          </Link>
                        ) : (
                          <span className="key-box">
                            {'Key: '}
                            <KeyBox resource={resourceKey} />
                          </span>
                        )}
                      </Label12Medium>
                    )}
                </div>
              </div>
            )}
            <div className="form-container">
              <div className="edit-area">
                <div className="form-content">
                  {title && (
                  <Box.Title>
                    {resourceKey ? 'Edit' : 'Create'}
                  </Box.Title>
                  )}
                  {renderFields && renderFields({
                    values,
                    setFieldValue,
                    queryResponse,
                    relatedItem,
                    submitForm,
                  })}
                  <Box.Content>
                    <div className="form-status">
                      {loading && (!saveError) && (<StatusLoading message="Hold on..." />)}
                      {saveError && (<StatusError error={saveError} />)}
                      {response && (<ResponseStatus status={response} />)}
                    </div>
                  </Box.Content>
                  {renderFooter && renderFooter({ values, setFieldValue })}
                </div>
              </div>
              {showSidebar && (
              <div className="action-area">
                <Box className="publish-box">
                  <Box.Content>
                    <SaveToolbar
                      errors={errors}
                      submitForm={submitForm}
                      isSubmitting={isSubmitting}
                      formCanSave={formCanSave}
                      loading={loading}
                      activeVersion={activeVersion}
                      saveError={saveError}
                      isValid={isValid}
                      data={data}
                      resourceName={resourceName}
                      topic={topic}
                      userColors={userColors}
                      resourceDef={resourceDef}
                      resourceKey={resourceKey}
                      versionKey={versionKey}
                      showActions={errorMode}
                      showHistory={showHistory}
                      showPinActions={showPinActions}
                      showReviewIcons={showReviewIcons}
                      imageUrl={(queryResponse && queryResponse.image && queryResponse.image.image_url) || ''}
                      resetRealtime={resetRealtime}
                    />
                  </Box.Content>
                </Box>
                <div>
                  {errorMode && (
                    <Box className="primary-actions-box">
                      <Box.Content>
                        {reviewMode
                          ? (
                            <Link className="plain edit-link" to="?review=0">
                              <EditPublishIcon />
                              <Label12Regular as="div">Edit</Label12Regular>
                              <ArrowRightIcon />
                            </Link>
                          )
                          : (
                            <Link className="plain" to="?review=1">
                              <ReviewIcon />
                              <Label12Regular as="div">Review</Label12Regular>
                              <ArrowRightIcon />
                            </Link>
                          )}
                        {/* <Link
                        className="plain"
                        to={
                        resourceDef.pageFor(
                          resourceDef,
                          'read',
                        )?.meta.urlFor({ key: resourceKey })
                      }
                      >
                        <PreviewIcon />
                        <Label12Regular as="div">Live Details</Label12Regular>
                        <ArrowRightIcon />
                      </Link> */}
                        {renderAdditionalLinks && renderAdditionalLinks()}
                      </Box.Content>
                    </Box>
                  )}
                  <Box.Collapsible
                    className="actions-box"
                    title="Actions"
                    open={false}
                    rememberKey="editor/action"
                  >
                    <PinActions
                      resourceType={resourceDef.storeId}
                      resourceKey={resourceKey}
                      resourceName={resourceName}
                      imageUrl={(queryResponse && queryResponse.image && queryResponse.image.image_url) || ''}
                    />
                    {enableDelete && (
                    <div className="delete-action">
                      <Label12Medium as="h5">Delete this resource?</Label12Medium>
                      <Label12Regular as="p">
                        Once you delete a resource,
                        there is no going back. Please be certain.
                      </Label12Regular>
                      {renderDeleteAction && renderDeleteAction(
                        resourceName,
                        resourceKey,
                        queryResponse,
                      )}
                    </div>
                    )}
                    {(errorMode || showAdditionalActions) && renderActions
                    && renderActions(
                      {
                        resourceKey,
                        realtimePath,
                        values,
                        queryResponse,
                        setFieldValue,
                      },
                    )}
                  </Box.Collapsible>
                  <Box.Collapsible
                    className="versions-box"
                    title="Version History"
                    rememberKey="editor/version-history"
                  >
                    <VersionRecordsView
                      resourceDef={resourceDef}
                      history={mergedHistory}
                      resourceKey={resourceKey}
                      maxItem={4}
                      versionKey={versionKey}
                    />
                  </Box.Collapsible>
                </div>
              </div>
              )}
            </div>
            {!showSidebar && showSaveToolbar && (
            <SaveToolbar
              errors={errors}
              submitForm={submitForm}
              isSubmitting={isSubmitting}
              formCanSave={formCanSave}
              loading={loading}
              activeVersion={activeVersion}
              saveError={saveError}
              isValid={isValid}
              data={data}
              resourceName={resourceName}
              topic={topic}
              reviewMode={reviewMode}
              userColors={userColors}
              resourceDef={resourceDef}
              resourceKey={resourceKey}
              versionKey={versionKey}
              showActions={errorMode}
              floatBar={floatSaveBar}
              showHistory={showHistory}
              showPinActions={showPinActions}
              showReviewIcons={showReviewIcons}
              imageUrl={(queryResponse && queryResponse.image && queryResponse.image.image_url) || ''}
              versionUrl={versionUrl}
              renderAdditionalLinks={renderAdditionalLinks}
              disableSaveAction={disableSaveAction}
              resetRealtime={resetRealtime}
            />
            )}
            <div className="review-container">
              {renderReviewContent && renderReviewContent(values, titleToShow, queryResponse)}
            </div>
          </Form>
        )}
      </FormBoundary>
    </AntFormWrapper>
  );
}

AntForm.propTypes = {
  resourceKey: PropTypes.string,
  realtimeResourceKey: PropTypes.string,
  resourceName: PropTypes.string,
  initValue: PropTypes.object,
  item: PropTypes.object,
  resourceDef: PropTypes.object,
  history: PropTypes.object,
  title: PropTypes.string,
  renderSubTitle: PropTypes.func,
  schema: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  action: PropTypes.object,
  isErrorMode: PropTypes.func,
  prepareVariables: PropTypes.func,
  responseKey: PropTypes.string.isRequired,
  responseStatusKey: PropTypes.string,
  queryResponse: PropTypes.object,
  relatedItem: PropTypes.object,
  versionKey: PropTypes.string,
  setSecondaryMenu: PropTypes.func,
  showReviewIcons: PropTypes.bool,
  showPinActions: PropTypes.bool,
  showHistory: PropTypes.bool,
  versionUrl: PropTypes.string,

  layout: PropTypes.string,

  renderFields: PropTypes.func,
  renderFooter: PropTypes.func,
  renderActions: PropTypes.func,
  renderDeleteAction: PropTypes.func,
  renderReviewContent: PropTypes.func,
  renderResourceImage: PropTypes.func,
  renderAdditionalLinks: PropTypes.func,

  watchChanges: PropTypes.bool,
  onChange: PropTypes.func,
  onDirty: PropTypes.func,
  onFormCanSave: PropTypes.func,
  onSaveSuccess: PropTypes.func,
  onChangeSet: PropTypes.func,
  showSaveToolbar: PropTypes.bool,
  skipLayoutUpdate: PropTypes.bool,
  reload: PropTypes.bool,
  secondaryMenuItemHeader: PropTypes.object,
  secondaryMenuItemhashkey: PropTypes.string,
  enableDelete: PropTypes.bool,
  resourceUrlParams: PropTypes.object,
  floatSaveBar: PropTypes.bool,
  showAdditionalActions: PropTypes.bool,
  showFloatAndTitleBar: PropTypes.bool,
  disableSaveAction: PropTypes.bool,
};
