import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { SCContextProvider } from './SCContext';

const Dashboard = lazy(() => import('./Dashboard'));
const List = lazy(() => import('./List'));
const Create = lazy(() => import('./Create'));
const ResourcePage = lazy(() => import('./ResourcePage'));
const Versions = lazy(() => import('./Versions'));
const Update = lazy(() => import('./Update'));

export function StructureContentRoutes() {
  return (
    <Routes>
      <Route path={Pages.cms.structure_content.meta.path}>
        <Route
          index
          element={(<Dashboard />)}
        />
        <Route
          path={Pages.cms.structure_content.list.meta.path}
          element={(<List />)}
        />
        {/* <Route
          path={Pages.cms.structure_content.create.meta.path}
          element={(<Create />)}
        />
        <Route
          path={Pages.cms.structure_content.read.meta.path}
          element={(<ResourcePage />)}
        />
        <Route
          path={Pages.cms.structure_content.versions.meta.path}
          element={(<Versions />)}
        />
        <Route
          path={Pages.cms.structure_content.update.meta.path}
          element={(<Update />)}
        /> */}
        <Route path={Pages.cms.structure_content.sc.meta.path}>
          <Route
            index
            element={(<SCContextProvider value={{}}><Dashboard /></SCContextProvider>)}
          />
          <Route
            path={Pages.cms.structure_content.list.meta.path}
            element={(<SCContextProvider value={{}}><List /></SCContextProvider>)}
          />
          <Route
            path={Pages.cms.structure_content.create.meta.path}
            element={(<SCContextProvider value={{}}><Create /></SCContextProvider>)}
          />
          <Route
            path={Pages.cms.structure_content.read.meta.path}
            element={(<SCContextProvider value={{}}><ResourcePage /></SCContextProvider>)}
          />
          <Route
            path={Pages.cms.structure_content.versions.meta.path}
            element={(<SCContextProvider value={{}}><Versions /></SCContextProvider>)}
          />
          <Route
            path={Pages.cms.structure_content.update.meta.path}
            element={(<SCContextProvider value={{}}><Update /></SCContextProvider>)}
          />
        </Route>
      </Route>
    </Routes>
  );
}
